import React, { useEffect, useState } from 'react';
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';



const PageSolicitarTarjetaDeCreditoBbva = props => {
  
  // const [adsenseLoaded, setAdsenseLoaded] = useState(false);
  // const [contentLoaded, setContentLoaded] = useState(false);

  // useEffect(() => {
  //   setAdsenseLoaded(true);
  //   console.log('cargando')
  //   const timer = setTimeout(() => {
  //     console.log('cargando2')
  //     setContentLoaded(true);
  //   }, 2000); // 3 seconds delay

  //   return () => clearTimeout(timer);
  // }, []);

  // if (!contentLoaded) {
  //   return (
  //     <div style={{ 
  //       textAlign: 'center', 
  //       padding: '50px', 
  //       fontWeight: '800', 
  //       color: 'gray', 
  //       fontFamily: 'helvetica', 
  //       backgroundColor: 'rgba(226, 226, 226, 0.5)', // Gray overlay
  //       height: '100vh', 
  //       display: 'flex', 
  //       alignItems: 'center', 
  //       justifyContent: 'center' 
  //     }}>
  //       <p>Cargando...</p>
  //     </div>
  //   );
  // }


  return (
    <Layout {...props}>
      <Seo title='Prestamos-Santander' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
     <Stack>
     </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Tarjeta de Crédito BBVA: Cómo Solicitarla y Aprovechar sus Beneficios Inmediatos💸💳'
          subheader='Descubre cómo solicitar la tarjeta de crédito BBVA online y disfruta de asistencia al viajero gratis, promociones exclusivas y más.'
        />
      </Stack>
    
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="/prestamos-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $650.000 💰 </Button>
        </Link>
        <Link to="/credito-hipotecario">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Sacá tu Crédito Hipotecario 💸🏡 </Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Visa Platinium 🤑 </Button>
        </Link>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
      </div>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
        Si estás buscando una tarjeta de crédito que no solo te brinde la flexibilidad de compras, sino también una serie de beneficios exclusivos, la <b>Tarjeta de Crédito BBVA Visa Platinum</b> es una excelente opción. En este artículo, te explicaremos las ventajas de esta tarjeta, cómo puedes <b>solicitar la tarjeta de crédito BBVA</b>, y los beneficios adicionales que hacen de esta tarjeta una herramienta esencial para cualquier viajero.
<br><br>
<b>Ventajas de la Tarjeta de Crédito BBVA Visa Platinum</b>
La tarjeta BBVA Visa Platinum ofrece una variedad de beneficios diseñados para mejorar tu experiencia de usuario y brindarte tranquilidad en tus viajes.
<br><br>
<ul>
  <li><b>Sumá Puntos BBVA</b>: Comprá con tu Tarjeta Visa Platinum y acumulá puntos BBVA que podés usar para viajes, compras, y experiencias únicas.</li>
  <li><b>Disfrutá asistencia al viajero</b>: Al pagar tu pasaje con tu tarjeta Visa Platinum, contás con <b>Visa asistencia al viajero gratis</b> hasta USD $150,000.</li>
  <li><b>Tu concierge las 24 horas</b>: Reservas, compras, excursiones y toda la gestión que requerís en el día a día gracias al servicio concierge.</li>
  <li><b>Recibí beneficios exclusivos</b>: Gracias a la Visa Luxury Hotel Collection obtené beneficios exclusivos en la colección de hoteles más prestigiosos del mundo.</li>
</ul>

          '
        />
      </Stack>
      <Adsense />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
      <b>Beneficios Adicionales de la Tarjeta de Crédito BBVA Visa Platinum</b>
Además de las ventajas mencionadas, la tarjeta BBVA Visa Platinum proporciona servicios adicionales que aseguran que estés cubierto en cualquier eventualidad durante tus viajes.
<br><br>
<ul>
  <li><b>Desembolso de efectivo de emergencia</b>: Sacá efectivo en caso de que tu tarjeta se pierda o la roben, tanto en tu lugar de domicilio o desde cualquier parte del mundo.</li>
  <li><b>Reemplazo de tarjetas por extravío o robo</b>: Despreocupate. El reemplazo sin cargo de tu tarjeta Visa Platinum, contás con el servicio global de reemplazo.</li>
  <li><b>Atención médica de emergencia</b>: Atendé tus problemas con tu Tarjeta Visa Platinum contás con asistencia médica con cobertura internacional presencial y online.</li>
</ul>
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="https://www.anses.blog/disfruta-de-tus-viajes-con-la-asistencia-al-viajero-visa-signature/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Disfruta de tus Viajes con la Asistencia al Viajero Visa Signature 💳🔥
          </Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          
          <b>Requisitos para Obtener la Tarjeta de Crédito BBVA Visa Platinum</b>
Para poder solicitar la tarjeta de crédito BBVA Visa Platinum, debes cumplir con ciertos requisitos:
<br><br>
<b>Perfil del solicitante</b>:
<br>
<ul>
  <li>Ser mayor de 18 años.</li>
  <li>Tener residencia permanente en Argentina.</li>
  <li>Ingresos mínimos $50,000.00, válido para Visa Platinum de Cuenta Premium.</li>
</ul>
<b>Antigüedad laboral</b>:
<br>
<ul>
  <li>Relación de dependencia: 3 meses en relación con BBVA + 3 meses en el trabajo actual.</li>
  <li>Monotributistas y autónomos: presentaciones de la actividad entre 1 y 2 años.</li>
</ul>
<b>Documentación</b>:
<br>
<ul>
  <li>DNI (Documento Nacional de Identidad).</li>
  <li>Recibos de haberes/constancia de ingresos.</li>
  <li>Constancia de domicilio.</li>
  <li>Constancia de CBU.</li>
</ul>
Tasa y Comisiones de la Tarjeta de Crédito BBVA Visa Platinum
Es importante conocer los costos asociados con la tarjeta de crédito BBVA Visa Platinum. Para consultar todas las <b>tasas</b> y <b>comisiones</b>, visita la sección de tarifas en el sitio web de BBVA.
<br>
Conclusión
La <b>Tarjeta de Crédito BBVA</b> Visa Platinum no solo te ofrece la capacidad de hacer compras en cualquier parte del mundo, sino que también te proporciona una amplia gama de beneficios que pueden hacer tu vida más fácil y tus viajes más placenteros. No esperes más y <b>solicita tu tarjeta de crédito BBVA</b> hoy mismo para disfrutar de todos estos beneficios y más. Con la tarjeta BBVA Visa Platinum, tenés la seguridad y comodidad en tus manos, listas para cualquier aventura.
<br><br>
¡Solicita tu <b>tarjeta de crédito online</b> y empieza a disfrutar de todos los beneficios de Visa Platinum!

          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/llegaron-los-creditos-hipotecarios-de-bbva-y-galicia-como-son-y-como-solicitar/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%',color: 'omegaDark' , fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Llegaron los Créditos Hipotecarios de BBVA y Galicia: ¿Cómo Son y Cómo Solicitar?🏡</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PageSolicitarTarjetaDeCreditoBbva
